<script>
import CarouselComponent from '../components/Carousel.vue';

export default {
  name: 'viewHouses',
  components: {
    CarouselComponent
  },
  data() {
    return {
      FolderName: "houses"
    };
  }
}
</script>

<template>
  <CarouselComponent :nameFolder="FolderName" />
</template>

<style scoped>
</style>
