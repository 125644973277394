import { createRouter, createWebHistory } from 'vue-router';
import viewMeditation from '../views/viewMeditation.vue';
import viewHome from '../views/viewHome.vue';
import viewHouses from '../views/viewHouses.vue';
import viewTravel from '../views/viewTravel.vue';

const routes = [
  {
    path: '/',
    redirect: () => {
      return 'home'
    }
  },
  {
    path: '/meditation',
    name: 'meditation',
    component: viewMeditation,
  },
  {
    path: '/home',
    name: 'home',
    component: viewHome,
  },
  {
    path: '/houses',
    name: 'houses',
    component: viewHouses,
  },
  {
    path: '/travel',
    name: 'travel',
    component: viewTravel,
  }
  // Add other routes as needed
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;