<template>
  <NavigationBar/>
  
  <router-view/>
</template>

<script>
import NavigationBar from "./global/Nav.vue"
export default {
  name: 'App',
  components: {
    NavigationBar,
  }
}
</script>

<style scoped>

.container {
  display: flex;
  align-content: space-around;
  background-color: aqua;
  border: #2c3e50;
  -ms-flex-align: center;
}
.logo {
  height: 60px;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
