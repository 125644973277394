<template>
  <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
  <ol class="carousel-indicators"></ol>
  <div style="margin-top: 5%;" class="carousel-inner"></div>
  <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Précédent</span>
  </a>
  <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Suivant</span>
  </a>
</div>
</template>

<script>
export default {
name: 'CarouselComponent',
props: {
  nameFolder: {
    type: String,
    required: true
  }
},
mounted() {
    // document.addEventListener("DOMContentLoaded", function() {
    var carouselIndicators = document.querySelector(".carousel-indicators");
    var carouselInner = document.querySelector(".carousel-inner");
  
    // Génération des indicateurs
    for (let i = 0; i < 7; i++) {
      let indicator = document.createElement("li");
      indicator.setAttribute("data-bs-target", "#carouselExampleIndicators");
      indicator.setAttribute("data-bs-slide-to", i);
      if (i === 0) {
        indicator.classList.add("active");
      }
      carouselIndicators.appendChild(indicator);
    }
  
    // Génération des images du carousel
    for (let i = 0; i < 7; i++) {
      let item = document.createElement("div");
      item.classList.add("carousel-item");
      if (i === 0) {
        item.classList.add("active");
      }
      
      let img = document.createElement("img");
      img.setAttribute("src", require(`@/assets/${this.nameFolder}/${this.nameFolder}_00${i+1}.jpg`));
      img.classList.add("w-100");
      img.setAttribute("alt", `Image_${this.nameFolder}_${i + 1}`);
      img.style.height = "500px";
      img.style.margin = "0 auto"; // Centre l'image horizontalement
      img.style.objectFit = "contain"; // Conserver le rapport hauteur/largeur
  
      item.appendChild(img);
      carouselInner.appendChild(item);
    }
  // });
}

// Add your component logic here
// ...
}
</script>

<style scoped>

.carousel-control-prev {
margin-left: 25%;

}

.carousel-control-next {
margin-right: 25%;

}
</style>
