<template>
  <!-- <div class="container"> -->
    <div class="row">
      <h1 class="col-md-12">Home</h1>
      <div class="col-md-7">
        <img class="img-fluid" src="../assets/home.jpeg" alt="image of a house">
      </div>
    </div>
  <!-- </div> -->
</template>

  
  <script>
  export default {
    name: 'viewHome'
  }
  </script>
  
  <style scoped>
  

h1 {
    text-align: center;
}

img {
  align-items: center;
  
    /* height: 500px; */
    /* margin-left: 25%; */
}
.row {
  display: flex;
  justify-content: center;
}

  </style>
  