<script>
    import CarouselComponent from '../components/Carousel.vue';
    
    export default {
      name: 'viewTravel',
      components: {
        CarouselComponent
      },
      data() {
        return {
          FolderName: "travel"
        };
      }
    }
    </script>
    
    <template>
      <CarouselComponent :nameFolder="FolderName" />
    </template>
    
    <style scoped>
    </style>
    