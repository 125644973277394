<template>
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
    <div class="container">
      <router-link class="navbar-brand route d-flex align-items-center" to="/home">
        <img src="../assets/logo.png" alt="Logo" class="logo"> Carousel
      </router-link>

      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
        aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item">
            <router-link class="nav-link route" to="/home">Home</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link route" to="/meditation">Meditation</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link route" to="/houses">Houses</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link route" to="/travel">Travel</router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>




  
  <script>
  export default {
    name: 'NavigationBar'
  }
  </script>
  
  <style scoped>

  .home {
    margin-left: 100px;
  }

  .route {
    margin-right: 40px;
  }
  .route:hover {
    /* background-color: bisque; */
    font-weight: bold;
    text-decoration-color: blue;
    transform: scale(1.5);
    /* margin-right: 80px;
    margin-left: 80px; */
  }
  .logo {
    height: 60px;
  }
  .navbar {
    padding: 10px;
  }
  
  .navbar-brand {
    font-size: 24px;
  }
  
  .navbar-nav .nav-link {
    font-size: 18px;
  }
  </style>
  
